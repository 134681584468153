import { createSlice } from "@reduxjs/toolkit";
import axiosHandler from '../components/axiosHandler';
import { encryptUsingAES256Base64, cryptAPIKey } from "../components/Security/crypt";
import { postEmployeeLoginUrl } from "../config/apiconfig";

export const getEmployeeInfoLoginAsync = (userNumber, password) => async (dispatch) => {
  try {
    var data = {
      'CardNumber': userNumber,
      'EmployeePortalPassword': password
    };
    var requestHeaders = {
      'Content-Type': 'application/json; charset=utf-8',
      'X-ApiKey': cryptAPIKey(process.env.REACT_APP_SECRET_KEY)
    };
    var encryptedData = encryptUsingAES256Base64(JSON.stringify(data));
    const response = await axiosHandler.post(postEmployeeLoginUrl, encryptedData, { headers: requestHeaders, timeout: 10000 });
    dispatch(setEmployeeAuth(response.data.auth));
    dispatch(setEmployeeMessage(response.data.results_message));
  }
  catch (error) {
    setEmployeeMessage(error)
  }
};

export const authTokenSlice = createSlice({
  name: "authToken",
  initialState: {
    employee: {
      auth: false,
      message: ''
    },
    customer: {
      auth: false,
      message: ''
    },
  },
  reducers: {
    setEmployLoading: (state, action) => {
      state.employee.loading = action.payload;
    },
    setEmployeeAuth: (state, action) => {
      localStorage.setItem('employeeAuthenticated', JSON.stringify(action.payload));
      state.employee.auth = action.payload;
    },
    setEmployeeMessage: (state, action) => {
      state.employee.message = action.payload;
    },
    setCustomerAuth: (state, action) => {
      localStorage.setItem('customerAuthenticated', JSON.stringify(action.payload));
      state.customer.auth = action.payload;
    },
    setCustomerMessage: (state, action) => {
      state.employee.message = action.payload;
    },
    clearEmployee: (state) => {
      localStorage.removeItem('employeeAuthenticated');
      state.employee.auth = false;
    },
    clearCustomer: (state) => {
      localStorage.removeItem('customerAuthenticated');
      state.customer.auth = false;
    },
  },
});

export const { setEmployeeAuth, setEmployeeMessage, setCustomerAuth, setCustomerMessage, clearEmployee, clearCustomer } = authTokenSlice.actions;

export default authTokenSlice.reducer;


// setEmployee and setCustomer: These actions will both update the Redux state and also save the authentication status to localStorage. This way, the authentication status persists even if the page is refreshed or if the user navigates away and comes back later.

// clearEmployee and clearCustomer: These actions will clear the Redux state and also remove the respective items from localStorage, effectively logging the user out.

// NOTE: When the app loads the Redux state is initialized based on what/s saved in local storage. This is done in App.js
