//Adding urls in seperate folder helps us to maintain api urls easily instead of going to each function and changing the url. 
//It's Also helpful for testing local or remote APIs similar to web.config connection strings

let apiURL;
let baseURL;
let sharedResourcesURL;

//Remote Hosted API (Prod Default)
// apiURL = 'https://triplecrownproducts.com/TcpWebAPI/api/';

//Remote Hosted API (Alternative Prod Default)
apiURL = 'https://cwserviceswebstore.com/TcpWebAPI/api/';

//Local Hosted API (Prod) 
// apiURL = 'https://remote.max.maxhat.com/TcpWebAPI/api/';

//localhost URL API (Testing)
// apiURL = 'https://localhost:44348/api/';

//Remote Hosted API (Testing)
// apiURL = "https://crownquality.com/TcpWebAPI/api/";

baseURL = apiURL;
sharedResourcesURL = apiURL + 'Shared/'

//API Routes
export const CurrentApiUrl = baseURL;
export const EditConentHtmlUrl = baseURL + "Content/EditConentHtml";
export const getProductsUrl = baseURL + "products";
export const postProductsListUrl = baseURL + "products/productslist";
export const getProductDetailsUrl = baseURL + "product/getproduct";
export const addProductToCartUrl = baseURL + "product/productaddtocart";
export const addProductLogoToCartUrl = baseURL + "product/productaddlogotocart";
export const cartDetailsUrl = baseURL + "viewcart";
export const updatePromoCodeUrl = baseURL + "updatepromotion";
export const postLogError = sharedResourcesURL + 'ErrorLog';
export const postCustomTitle = sharedResourcesURL + 'CustomTitle';
export const postCustomMetaDescription = baseURL + "custom/metaDescription";
export const postSageProductSearchUrl = baseURL + "Sage/ProductSearch";
export const postSendEmailUrl = sharedResourcesURL + 'SendEmail';
export const postSageConnectProductSearchUrl = baseURL + "SageConnect/ProductSearch"
export const postEmployeeLoginUrl = sharedResourcesURL + 'EmployeeLogin';
export const postWebsiteSettings = baseURL + 'WebsiteSettings';
export const postWebsiteVersionUrl = sharedResourcesURL + 'WebsiteVersion';
export const LoginUrl = baseURL + 'SalesCenter/Login';
export const ApiCallUrl = sharedResourcesURL + 'ApiCall';