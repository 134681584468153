import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { postEmployeeLogin } from "../../Redux/siteWideSettingsSlice";
import '../../styles/_AELogin.scss';

function AELogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(postEmployeeLogin(email, password));
  };

  return (
    <>
      <h2 id="aelogin-header">Employee Login</h2>
    <div className="login-card">
      <form onSubmit={handleSubmit}>
        <div className="form-field">
          <label htmlFor="email">User Name:</label>
          <input
            type="text"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-field">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="login-button">Login</button>
        </form>
        <a href="https://remote.max.maxhat.com/TcpSalesCenterV3/ResetPassword" className="forgot-password-link">Forgot Password?</a>
      </div>
      </>
  );
}

export default AELogin;
