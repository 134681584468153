import React from "react";

import AELogin from "../components/AddEdit/AELogin";
import { useSelector } from "react-redux";
import "../styles/_AEHome.scss";



function AEHome() {
  const isStaffLoggedIn = useSelector((state) => state?.siteWideSettings?.EmployeeUser?.LoggedIn);


  return (
    <div className="aeHome">
      {
        isStaffLoggedIn
          ? <div className="success-message">SUCCESS: You are logged in</div>
          : <AELogin />
      }
    </div>
  );
}

export default AEHome;
