import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmployeeUserLogout, toggleCMSEditButton, toggleTestDate, setTestDateValue } from "../Redux/siteWideSettingsSlice";
import ErrorBoundary from "./ErrorBoundary";
import ErrorMessage from "../pages/errors/ErrorMessage";
import { ApiCall } from "./ApiCall";
import FormattedDate from "./DataManipulation/FormatDate";
import { CurrentApiUrl } from "../config/apiconfig";
import "../styles/AdminTool.scss";

function AdminTool() {
  const dispatch = useDispatch();
  const websiteVersion = useSelector((state) => state.siteWideSettings?.websiteVersion?.version);
  const activeDate = useSelector((state) => state.siteWideSettings?.activeDate);
  const pageMeta = useSelector((state) => state.siteWideSettings?.pageMeta);
  const EmployeeUser = useSelector((state) => state.siteWideSettings?.EmployeeUser);

  const handleSave = async () => {
    const title = document.getElementById("customTitleInput").value;
    const description = document.getElementById("customMetaDescriptionInput").value;

    var data = {
      'Title': title,
      'MetaKeywords': '',
      'MetaDescription': description,
      'WebsiteID': 1
    }

    if (pageMeta?.seoFor === 'Selected Attributes') {
      data['SelectedAttributes'] = pageMeta?.seoProperty;
    } else if (pageMeta?.seoFor === 'ProductID') {
      data['ProductID'] = pageMeta?.seoProperty;
    } else if (pageMeta?.seoFor === 'Url Path') {
      data['RawURL'] = pageMeta?.seoProperty;
    } else {
      return;
    }

    await ApiCall('usp_SEO_Edit', data);
  };

  return (
    <ErrorBoundary componentName="AdminTool" fallback={<ErrorMessage />}>
      <>
        <div className="admin-tool-container">
          <div className="row">
            <label id="location-pathname-label" className="col-3">
              {pageMeta?.seoFor}:
            </label>
            <span id="location-pathname-url" className="setting-value col-9">
              {pageMeta?.seoProperty}
            </span>
          </div>
          <div className="row">
            <label className="col-3">Custom Title:</label>
            <input
              className="col-8 ms-2"
              type="text"
              id="customTitleInput"
              defaultValue={pageMeta?.customTitle}
            />
          </div>
          <div className="row">
            <label className="col-3">Custom Meta Description:</label>
            <input
              className="col-8 ms-2"
              type="text"
              id="customMetaDescriptionInput"
              defaultValue={pageMeta?.customDescription}
            />
          </div>
          <div className="row">
            <div className="col-3"></div>
            <div className="col-8">
              <button className="btn-primary-custom" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
          <div className="row">
            <label className="col-3">Logged In As:</label>
            <span className="setting-value col-9">
              {EmployeeUser?.LoggedIn ? (
                <>
                  {EmployeeUser?.Name}{" "}
                  <button className="btn-primary-custom" onClick={() => dispatch(EmployeeUserLogout())}>
                    Log Out
                  </button>
                </>
              ) : (
                "Not Logged In"
              )}
            </span>
          </div>
          <div className="row">
            <label className="col-3">Show CMS Edit Button:</label>
            <div className="col-9">
              <input
                type="checkbox"
                checked={EmployeeUser?.CMSEditButton}
                onChange={(e) => dispatch(toggleCMSEditButton(e.target.checked))}
              />
            </div>
          </div>
          <div className="row">
            <label className="col-3">Select Test Date:</label>
            <div className="col-9">
              <input
                type="date"
                id="testDate"
                value={EmployeeUser?.TestDate}
                onChange={(e) => dispatch(setTestDateValue(e.target.value))}
              />
            </div>
          </div>
          <div className="row">
            <label className="col-3">Date Used:</label>
            <div className="col-9">
              <span className="setting-value date-special-format">
                <FormattedDate date={activeDate} dateFormat='MM/dd/yyyy' />
              </span>
            </div>
          </div>
          <div className="row">
            <label className="col-3">Activate Test Date:</label>
            <div className="col-9">
              <input
                type="checkbox"
                id="testDateActive"
                checked={EmployeeUser?.TestDateActive}
                onChange={(e) => dispatch(toggleTestDate(e.target.checked))}
              />
            </div>
          </div>
          <div className="row">
            <label className="col-3">API URL:</label>
            <div className="col-9">
              <span className="setting-value date-special-format">
                {CurrentApiUrl}
              </span>
            </div>
            </div>
          <div className="row">
            <label className="col-3">Website Version:</label>
            <div className="col-9">
              <span className="setting-value">
                {websiteVersion}
              </span>
            </div>
          </div>
        </div>
      </>
    </ErrorBoundary>
  );
}

export default AdminTool;
