import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RequireAuth = () => {
  const authTokenEmployee = useSelector((state) => state.authToken.employee.auth);
  const location = useLocation()

  return (
    authTokenEmployee
      ?
      <Outlet />
      :
      <Navigate to='/employeeinformation/login' state={{ from: location }} replace />
  )
}
export default RequireAuth
