import React from 'react';
import { useSelector } from 'react-redux';
import ErrorBoundary from '../components/ErrorBoundary';
import ErrorMessage from '../pages/errors/ErrorMessage';

function Loading({ fullscreen = false }) {
  const ImageUrl = useSelector((state) => state?.siteWideSettings?.imageServerUrl);
  const className = fullscreen ? 'loading-fullscreen' : 'loading-gif'; 

  return (
    <ErrorBoundary componentName="Loading" fallback={<ErrorMessage />}>
      <div className={className}>
        <img src={`${ImageUrl}adfiles/8167-TCPLoading_Optimized.gif`} alt='Loading...' />
      </div>
    </ErrorBoundary>
  );
}

export default Loading;
