import { configureStore } from "@reduxjs/toolkit";

import ProductsReducer from '../Redux/productsSlice';
import BillingInputsReducer from '../Redux/billingInputsSlice';
import ShippingInputsReducer from '../Redux/shippingInputsSlice';
import SiteWideSettingsReducer from '../Redux/siteWideSettingsSlice';
import CartReducer from '../Redux/cartSlice';

//Review if still needed
import authReducer from '../Redux/loggedInSlice';
import authTokenReducer from "../Redux/authTokenSlice";

const store = configureStore({
  reducer: {
    products: ProductsReducer,
    addBillingInputs: BillingInputsReducer,
    addShippingInputs: ShippingInputsReducer,
    siteWideSettings: SiteWideSettingsReducer,
    cart: CartReducer,

    //Review if still needed
    auth: authReducer,
    authToken: authTokenReducer,
  },
});

export default store;