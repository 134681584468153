import cookie from "react-cookies";
import { decryptString, encryptString } from "../components/Security/crypt";

export function getSelectionsLink(
  dtSelections,
  presenter,
  attributeID,
  searchWords,
  websitePageName
) {
  let link = "";
  let selections = "";
  dtSelections.forEach((selection) => {
    selections += selection.AttributeID + ",";
    link += selection.Presenter.toLowerCase();
  });
  selections += attributeID + ",";
  link =
    "/products/" +
    link +
    "-" +
    presenter.toLowerCase() +
    "-embroidered-printed-custom-logo.htm?s=" +
    selections;

  if (websitePageName && websitePageName !== "") {
    link += "&w=" + websitePageName;
  }

  if (searchWords && searchWords !== "") {
    link += "&search=" + searchWords;
  }

  return link;
}

export function getProductsLink(
  dtSelections,
  websitePageName,
  attributeID,
  excludedAttributeID
) {
  let link = "";
  let selections = "";

  dtSelections.forEach((selection) => {
    if (selection.AttributeID !== excludedAttributeID) {
      selections += selection.AttributeID + ",";
      link += selection.Presenter.toLowerCase();
    }
  });
  link =
    "/products/" +
    link +
    "-embroidered-printed-custom-logo.htm?s=" +
    selections;

  if (websitePageName && websitePageName !== "") {
    link += "&w=" + websitePageName;
  }
  return link;
}

export function setCookieByName(key, val) {
  const expires = new Date();
  //This line is for a year
  expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 365);
  //This line is for 2 hours for testing
  // expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 1);
  cookie.save(key, encryptString(val), {
    path: "/",
    expires,
    maxAge: 1000 * 60 * 60,
  });
}

export function getCookieByName(key) {
  // let x = encryptString(key);
  return cookie.load(key) ? decryptString(cookie.load(key)) : null;
}

export function priceText(price) {
  return formatter.format(price);
}

export function priceTextWithAboveMin(price, aboveMin) {
  return aboveMin !== 0 ? formatter.format(price) : "N/A";
}

// Create our number formatter.
var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});


export const loadSageProducts = (pageNum, itemsCountPerPage, totalProducts) => {
 
  let iBegin = ((pageNum - 1) * itemsCountPerPage + 1);
  let iEnd = iBegin + (itemsCountPerPage - 1);
  let iSageReturn = 0;

  if (totalProducts === 0) {
      // No tcp products will be shown; all sage products will be shown.
      iSageReturn = itemsCountPerPage;
  } else if (totalProducts > iEnd) {
      // Only tcp products will be shown.
      iSageReturn = 0;
  } else if (totalProducts >= iBegin) {
      // Some tcp and some sage will be shown.
      iSageReturn = iEnd - totalProducts;
      iBegin = 1;
  } else if (totalProducts < iBegin) {
      // Only sage products will be shown.
      iBegin = iBegin - totalProducts;
      iSageReturn = itemsCountPerPage;
  }

  return {
      startNumber: iBegin,
      sageProductsCount: iSageReturn
  };
};
