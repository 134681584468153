import React, { useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useNavigate } from "react-router-dom";
import Loading from "./components/loading";
import "./App.css";
import { useMediaQuery } from "react-responsive";
import { getCookieByName } from "./CustomFunctions/MyFunctions";
import YouAreSpecial from "./pages/YouAreSpecial.js";
import NotFound from "./pages/errors/NotFound";
import ErrorBoundary from "./components/ErrorBoundary";
import ErrorMessage from './pages/errors/ErrorMessage';
import { setActiveDate, postWebsiteSettings } from '../src/Redux/siteWideSettingsSlice';
import PageViewTracker from '../src/components/GoogleAnalytics/PageViewTracker';
import EmployeesBanner from "./pages/EmployeesBanner";
import EmployeeInfo from "./pages/EmployeeInfo";
import RequireAuth from "./components/RequireAuth";
import EmployeeInfoLogin from "./components/Employee/EmployeeInfoLogin";
import { allowCrawling, getActiveImageServer } from "../src/Redux/siteWideSettingsSlice";
import AEHome from "./pages/AEHome.js";
import Settings from "./pages/settings.js";
import PageMeta from "./components/PageMeta.js";

const Navbar = React.lazy(() => import("./components/HeaderMain/navbar"));
const Home = React.lazy(() => import("./pages/home"));
const Products = React.lazy(() => import("./pages/products"));
// const Product = React.lazy(() => import("./pages/product"));
const Footer = React.lazy(() => import("./components/footer"));
// const Cart = React.lazy(() => import("./pages/cart"));
// const Checkout = React.lazy(() => import("./pages/checkout"));
// const Clearance = React.lazy(() => import("./pages/clearance"));
// const Blog = React.lazy(() => import("./pages/blog"));
const Reroute = React.lazy(() => import("./components/reroute"));
// const Promotionals = React.lazy(() => import("./pages/promotionals"));
const { setEmployeeAuth, setCustomerAuth } = require("./Redux/authTokenSlice");

const App = () => {
  //TODO site store maintenane script can be moved to its own component
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });
  const isLargeScreen = useMediaQuery({ query: "(min-width: 1224px)" });
  const showNavbar = useSelector((state) => state.siteWideSettings.showNavbar);
  const showFooter = useSelector((state) => state.siteWideSettings.showFooter);
  const activeDate = useSelector((state) => state.siteWideSettings.activeDate);
  const testDateActive = useSelector((state) => state.siteWideSettings?.EmployeeUser?.TestDateActive);
  const userDate = useSelector((state) => state.siteWideSettings.activeDate);
  const websiteExpiration = useSelector((state) => state?.siteWideSettings?.websiteVersion?.expiration);

  useEffect(() => {
    async function fetch() {
      dispatch(getActiveImageServer());
    };

    fetch();
  }, [dispatch]);

  useEffect(() => {
    if (!testDateActive === true) {
      const currentDate = new Date().toISOString().substring(0, 10);
      if (currentDate > activeDate) {
        dispatch(setActiveDate(currentDate));
      }
    }
  }, [dispatch, activeDate, testDateActive, userDate]);

  useEffect(() => {
    const employeeAuthenticated = JSON.parse(localStorage.getItem('employeeAuthenticated'));
    if (employeeAuthenticated) {
      dispatch(setEmployeeAuth(true));
    }

    const customerAuthenticated = JSON.parse(localStorage.getItem('customerAuthenticated'));
    if (customerAuthenticated) {
      dispatch(setCustomerAuth(true));
    }
  }, [dispatch]);

  useEffect(() => {
    const checkVersionExpiration = async () => {
      const currentTime = Date.now();
      if (typeof websiteExpiration === 'undefined' || currentTime > websiteExpiration) {
        await dispatch(postWebsiteSettings());
      }
    };

    checkVersionExpiration();
    //30 Seconds
    const interval = setInterval(checkVersionExpiration, 1 * 30 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, websiteExpiration]);

  useEffect(() => {
    if (!window.location.pathname.startsWith("/products")) {
      allowCrawling();
    }
  }, [navigate]);

  return (
    <ErrorBoundary componentName="App" fallback={<ErrorMessage />}>
      <div className="App">
        <Suspense fallback={<Loading fullscreen={true} />}>
          <PageMeta />
          <PageViewTracker />
          {showNavbar && (
            <Navbar
              isDesktop={isDesktop}
              isLargeScreen={isLargeScreen}
            />
          )}
          <div className="page-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="home" element={<Home />} />
              <Route path="youarespecial" element={<YouAreSpecial />} />
              <Route path="employeesbanner" element={<EmployeesBanner />} />
              <Route path="employeeinformation/login" element={<EmployeeInfoLogin />} />
              <Route path="addedit/login" element={<AEHome pageName="aelogin" />} />
              <Route element={<RequireAuth />}>
                <Route path="employeeinformation" element={<EmployeeInfo lang="" />} />
                <Route path="employeeinformation/spa" element={<EmployeeInfo lang="spa" />} />
                <Route path="employeeinformation/eng" element={<EmployeeInfo lang="eng" />} />
              </Route>
              <Route path="settings" element={<Settings />} />
              <Route path="products/:url" element={<Products websitePageName="" webOrderID={getCookieByName("WebOrderID") || 0} />} />
              <Route path="products" element={<Products websitePageName="" webOrderID={getCookieByName("WebOrderID") || 0} />} />

              {/* TODO: We need a Logic to decide which page to show based on the url basically when the URL ends in a paramater.  Test on selected attributes conatining only AttributeID: 14 in dtSelectedAttributes*/}

              <Route path="404-not-found" element={<NotFound />} />


              {/* ### This route * will go into more condition based routing */}
              <Route path="*" element={<Reroute />} />

              {/* ### Routes below should be commented out. If the route should be active it should be moved above the Reroute */}
              {/* <Route path="promotionals" element={<Promotionals />} /> */}
              {/* <Route path="blog" element={<Blog />} /> */}
              {/* <Route path="clearance" element={<Clearance />} /> */}
              {/* <Route path="cart" element={<Cart websitePageName="" />} /> */}
              {/* <Route path="product/:url" element={<Product websitePageName="" isDesktop={isDesktop} />} /> */}
              {/* <Route path="checkout" element={<Checkout websitePageName="" />} /> */}
              {/* <Route path="designproduct" element={<DesignProduct />} /> */}
            </Routes>
          </div>
          {showFooter && (<Footer />)}
        </Suspense>
      </div>
    </ErrorBoundary>
  );
};

export default App;
