import { createSlice } from "@reduxjs/toolkit";
import { cryptAPIKey, encryptUsingAES256Base64, decryptUsingAES256Base64 } from "../components/Security/crypt";
import { postWebsiteVersionUrl, postSendEmailUrl, LoginUrl } from "../config/apiconfig";
import axiosHandler from '../components/axiosHandler';
import axios from "axios";
import TcpMaxDatabase from "../components/TcpMaxDatabase";
import { ApiCall } from "../components/ApiCall";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

let UserCookie = "CMSUser";

const getCookie = (name) => {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  if (parts.length === 2) return parts.pop().split(";").shift();
};

const getLocalArrayValue = (localStorageKey, arrayKey, defaultValue = '') => {
  const encryptedValue = localStorage.getItem(localStorageKey);

  if (!encryptedValue) {
    return defaultValue;
  } else {
    try {
      const decryptedValue = decryptUsingAES256Base64(encryptedValue);
      const storedArray = JSON.parse(decryptedValue) || {};
      const value = storedArray[arrayKey];
      return value !== undefined ? value : defaultValue;
    } catch (error) {
      console.error("Error decrypting or parsing localStorage value:", error);
      return defaultValue;
    }
  }
}

const setCookieDays = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
};

const setCookieHours = (name, value, hours) => {
  const date = new Date();
  date.setTime(date.getTime() + hours * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
};

export const postWebsiteSettings = (Version) => async (dispatch) => {
  var data = {
    'App': 'TcpReact',
    'Version': Version
  };

  var requestHeaders = {
    'Content-Type': 'application/json; charset=utf-8',
    'X-ApiKey': cryptAPIKey(process.env.REACT_APP_SECRET_KEY)
  };

  var encryptedData = encryptUsingAES256Base64(JSON.stringify(data));
  const response = await axiosHandler.post(postWebsiteVersionUrl, encryptedData, { headers: requestHeaders, timeout: 30000, });
  const WebsiteVersion = JSON.parse(response.data.website_version);
  dispatch(WebsiteVersionRefresh(WebsiteVersion));
}

export const WebsiteVersionRefresh = (WebsiteVersion) => (dispatch) => {
  dispatch(setWebsiteVersion(WebsiteVersion));
  //5 Minutes
  dispatch(setWebsiteExpiration(Date.now() + (5 * 60 * 1000)))
}

export const postSendEmailAsync = (ToAddress, CcAddress, Subject, Message, Html, FromAddress) => async () => {
  const requestHeaders = {
    'Content-Type': 'application/json; charset=utf-8',
    'X-ApiKey': cryptAPIKey(process.env.REACT_APP_SECRET_KEY)
  };

  let SendFromRemoteServer = false; //TODO add logic to determine remote or local

  const requestData = {
    ToAddress: ToAddress,
    CcAddress: CcAddress,
    Subject: Subject,
    Message: Message,
    Html: Html,
    FromAddress: FromAddress,
    SendFromRemoteServer: SendFromRemoteServer,
  };

  const encryptedData = encryptUsingAES256Base64(JSON.stringify(requestData));

  try {
    await axiosHandler.post(postSendEmailUrl, encryptedData, { headers: requestHeaders, timeout: 30000 });
  } catch (error) {

  }
}

export const preventCrawling = () => {
  const existingMetaTag = document.querySelector('meta[name="robots"]');
  if (!existingMetaTag) {
    const metaTag = document.createElement('meta');
    metaTag.name = 'robots';
    metaTag.content = 'noindex';
    document.head.appendChild(metaTag);
  }
};

export const allowCrawling = () => {
  const existingMetaTag = document.querySelector('meta[name="robots"]');
  if (existingMetaTag && existingMetaTag.content === 'noindex') {
    document.head.removeChild(existingMetaTag);
  }
};

export const postEmployeeLogin = (Email, Password) => async (dispatch) => {
  let results = 'Failed';
  let resultsMessage = '';
  let dtLoginResults = []
  let dtUserAccount = [];

  try {
    var data = {
      'Email': Email,
      'Password': Password
    }

    TcpMaxDatabase(data);

    var requestHeaders = {
      'Content-Type': 'application/json; charset=utf-8',
      'X-ApiKey': cryptAPIKey(process.env.REACT_APP_SECRET_KEY)
    }

    var encryptedData = encryptUsingAES256Base64(JSON.stringify(data));
    const response = await axios.post(LoginUrl, encryptedData, { headers: requestHeaders, timeout: 30000, });

    dtLoginResults = JSON.parse(response.data.dt_login_results);
    dtUserAccount = JSON.parse(response.data.dt_user_account);
    results = dtLoginResults[0]["LoginResults"] || 'Failed';
    resultsMessage = dtLoginResults[0]["LoginMessage"] || '';

    const EmployeeUserAccount = {
      Active: dtUserAccount[0]["Active"],
      Admin: dtUserAccount[0]["Admin"],
      CardNumber: dtUserAccount[0]["CardNumber"],
      ID: dtUserAccount[0]["ID"],
      LoggedIn: dtLoginResults[0]["LoginResults"] === "Successful" ? true : false,
      Email: dtUserAccount[0]["Email"],
      Name: dtUserAccount[0]["Name"],
      PulseIDAccount: dtUserAccount[0]["PulseIDAccount"],
      ResetPassword: dtUserAccount[0]["ResetPassword"],
      SalesRepID: dtUserAccount[0]["SalesRepID"],
      UserLevel: dtUserAccount[0]["UserLevel"],
      UserName: dtUserAccount[0]["UserName"],
      UserType: dtUserAccount[0]["UserType"]
    };
    dispatch(setEmployeeUser(EmployeeUserAccount))
  } catch (error) {
    resultsMessage = 'An error occurred while confirming password. Error: ' + error;
  }
  finally {
    return { results, resultsMessage, };
  }
}

export const getActiveImageServer = () => async (dispatch) => {
  let ImageServer = 'SERV12';
  let ImageServerUrl = '';
  const Serv12Url = 'https://remote.max.maxhat.com/Images/';
  const Serv12etUrl = 'https://sc2.maxapparel.com/Images/';

  const response = await ApiCall('usp_TcpWebsiteImageUrl', {})
  ImageServer = response?.data?.Table[0]["ServerName"];

  if (ImageServer === 'SERV12') {
    ImageServerUrl = Serv12Url;
  } else if (ImageServer === 'SERV12ET') {
    ImageServerUrl = Serv12etUrl
  } else {
    ImageServerUrl = Serv12Url;
  }

  var data = {
    'imageServer': ImageServer,
    'imageServerUrl': ImageServerUrl,
    'TcpUrl': Serv12Url,
    'MaxUrl': Serv12etUrl
  }

  dispatch(setImageServer(data));

  return (data);
}

export const updatePageMeta = (
  title = null,
  canonical = null,
  description = 'Full service provider of custom logo apparel and promotional products. Offering top name brand apparel such as Under Armour, Nike, Carhart and more at affordable prices. Enjoy working with a dedicated sales representative to help whenever needed. And our full service graphic design and production experts will ensure your logo looks great on every product.',
  keywords = null,
  productID = null,
) => async (dispatch) => {
  const location = useLocation();
  const activeDate = useSelector((state) => state?.siteWideSettings?.activeDate);
  const websiteID = useSelector((state) => state?.siteWideSettings?.websiteID);
  let seoID = 0;
  let seoFor = '';
  let seoProperty = '';
  let customTitle = '';
  let customDescription = '';
  const queryParams = new URLSearchParams(location.search);
  const pathname = location.pathname;
  const selectedAttributes = queryParams.get('s') || '';

  if (title === null || title === '') {
    title = `Triple Crown Products`;
  } else if (!title.includes('|')) {
    // If the title does NOT includes '|', add 'Triple Crown Products' at the end    
    title = `${title} | Triple Crown Products`;
  } else {
    title = `${title}`;
  }

  if (selectedAttributes !== '') {
    seoFor = 'Selected Attributes';
    seoProperty = selectedAttributes;
  } else if (productID > 0) {
    seoFor = 'ProductID';
    seoProperty = productID;
  } else {
    seoFor = 'Url Path';
    seoProperty = pathname;
  }

  var data = {
    AdLocationIDs: '10',
    UseDate: activeDate,
    WebsiteID: websiteID,
    SelectedAttributes: seoFor === 'Selected Attributes' ? selectedAttributes : '',
    ProductID: seoFor === 'ProductID' ? productID : 0,
    SizeGroupID: 0,
    BrandID: 0,
    WebOrderID: 0, //TODO: update to customer WebOrderID
    UrlPath: seoFor === 'Url Path' ? pathname : ''
  }

  const response = await ApiCall('tq_Ads_V9', data)

  seoID = response?.data?.Table3[0]?.SEOID || 0;
  customTitle = response?.data?.Table3[0]?.Title || '';
  customDescription = response?.data?.Table3[0]?.MetaDescription || '';

  if (canonical === null) {
    canonical = 'https://triplecrownproducts.com';
  } else if (!canonical.includes('https://triplecrownproducts.com/') && !canonical.includes('https://www.triplecrownproducts.com/')) {
    canonical = `https://triplecrownproducts.com/${canonical}`;
  } else if (canonical.includes('https://www.')) {
    canonical = canonical.replace('https://www.', 'https://');
  } else if (canonical.includes('http://www.')) {
    canonical = canonical.replace('http://www.', 'https://');
  } else if (canonical.includes('http://')) {
    canonical = canonical.replace('http://', 'https://');
  }

  var results = {
    title: title,
    canonical: canonical,
    description: description,
    keywords: keywords || '',
    seoID: seoID,
    seoFor: seoFor,
    seoProperty: seoProperty,
    customTitle: customTitle,
    customDescription: customDescription,
  }

  await dispatch(setPageMeta(results));
}

export const toggleCMSEditButton = (value) => async (dispatch) => {
  await dispatch(setCMSEditButton(value));
}

export const EmployeeUserLogout = () => async (dispatch) => {
  dispatch(clearEmployeeUser());
}

export const toggleTestDate = (value) => async (dispatch) => {
  dispatch(setTestDateActive(value));
}

export const setTestDateValue = (value) => async (dispatch) => {
  dispatch(setTestDate(value));
}

const EmployeeUserDefault = {
  Active: getLocalArrayValue(UserCookie, "Active"),
  Admin: getLocalArrayValue(UserCookie, "Admin"),
  CardNumber: getLocalArrayValue(UserCookie, "CardNumber"),
  ID: getLocalArrayValue(UserCookie, "ID"),
  LoggedIn: getLocalArrayValue(UserCookie, "LoggedIn") || false,
  Email: getLocalArrayValue(UserCookie, "Email"),
  Name: getLocalArrayValue(UserCookie, "Name"),
  PulseIDAccount: getLocalArrayValue(UserCookie, "PulseIDAccount"),
  ResetPassword: getLocalArrayValue(UserCookie, "ResetPassword"),
  SalesRepID: getLocalArrayValue(UserCookie, "SalesRepID"),
  UserLevel: getLocalArrayValue(UserCookie, "UserLevel"),
  UserName: getLocalArrayValue(UserCookie, "UserName"),
  UserType: getLocalArrayValue(UserCookie, "UserType"),
  TestDate: getLocalArrayValue(UserCookie, "TestDate") || new Date().toISOString().substring(0, 10),
  TestDateActive: getLocalArrayValue(UserCookie, "TestDateActive") || false,
  CMSEditButton: getLocalArrayValue(UserCookie, "CMSEditButton") || false
}

const siteWideSettingsSlice = createSlice({
  name: "siteWideSettings",
  initialState: {
    EmployeeUser: EmployeeUserDefault,
    activeDate:
      getLocalArrayValue(UserCookie, "TestDateActive") === "true"
        ? getLocalArrayValue(UserCookie, "TestDate") || new Date().toISOString().substring(0, 10)
        : getCookie("activeDate") || new Date().toISOString().substring(0, 10),
    websiteID: "1",
    adsData: null,
    websiteVersion: {
      version: '',
      expiration: 0,
      autoRefresh: false,
      autoRefreshTries: parseInt(getCookie("autoRefreshTries")) || 0,
      autoRefreshDateTime: ''
    },
    showNavbar: true,
    showFooter: true,
    dtContent: null,
    imageServer: 'SERV12',
    imageServerUrl: 'https://remote.max.maxhat.com/Images/',
    pageMeta: {
      title: 'Triple Crown Products',
      canonical: 'https://triplecrownproducts.com',
      description: 'Full service provider of custom logo apparel and promotional products. Offering top name brand apparel such as Under Armour, Nike, Carhart and more at affordable prices. Enjoy working with a dedicated sales representative to help whenever needed. And our full service graphic design and production experts will ensure your logo looks great on every product.',
      keywords: '',
      seoID: 0,
      seoFor: '',
      seoProperty: '',
      customTitle: '',
      customDescription: '',
    },
  },
  reducers: {
    setEmployeeUser: (state, action) => {
      state.EmployeeUser.Active = action.payload.Active || false;
      state.EmployeeUser.Admin = action.payload.Admin || false;
      state.EmployeeUser.CardNumber = action.payload.CardNumber || '';
      state.EmployeeUser.ID = action.payload.ID || '';
      state.EmployeeUser.LoggedIn = action.payload.LoggedIn || false;
      state.EmployeeUser.Email = action.payload.Email || '';
      state.EmployeeUser.Name = action.payload.Name || '';
      state.EmployeeUser.PulseIDAccount = action.payload.PulseIDAccount || false;
      state.EmployeeUser.ResetPassword = action.payload.ResetPassword || false;
      state.EmployeeUser.SalesRepID = action.payload.SalesRepID || '';
      state.EmployeeUser.UserLevel = action.payload.UserLevel || '';
      state.EmployeeUser.UserName = action.payload.UserName || '';
      state.EmployeeUser.UserType = action.payload.UserType || '';

      localStorage.setItem(UserCookie, encryptUsingAES256Base64(JSON.stringify(state.EmployeeUser)));
    },
    setTestDate: (state, action) => {
      state.EmployeeUser.TestDate = action.payload;
      localStorage.setItem(UserCookie, encryptUsingAES256Base64(JSON.stringify(state.EmployeeUser)));
    },
    setTestDateActive: (state, action) => {
      state.EmployeeUser.TestDateActive = action.payload;

      if (action.payload) {
        // If test date is active, set activeDate to testDate
        state.activeDate = state.EmployeeUser?.TestDate;
        setCookieDays("activeDate", state.EmployeeUser?.TestDate, 1);
      } else {
        // If test date is not active, set activeDate to the current date
        const currentDate = new Date().toISOString().substring(0, 10);
        state.activeDate = currentDate;
        setCookieDays("activeDate", currentDate, 1);
      }

      localStorage.setItem(UserCookie, encryptUsingAES256Base64(JSON.stringify(state.EmployeeUser)));
    },
    setCMSEditButton: (state, action) => {
      state.EmployeeUser.CMSEditButton = action.payload;
      localStorage.setItem(UserCookie, encryptUsingAES256Base64(JSON.stringify(state.EmployeeUser)));
    },
    setImageServer: (state, action) => {
      state.imageServer = action.payload.imageServer;
      state.imageServerUrl = action.payload.imageServerUrl;
    },
    setActiveDate: (state, action) => {
      state.activeDate = action.payload;
      setCookieDays("activeDate", action.payload, 1);
    },
    setWebsiteID: (state, action) => {
      state.websiteID = action.payload;
    },
    setWebsiteVersion: (state, action) => {
      state.websiteVersion.version = action.payload;
    },
    setWebsiteExpiration: (state, action) => {
      state.websiteVersion.expiration = action.payload;
    },
    setAutoRefresh: (state, action) => {
      state.websiteVersion.autoRefresh = action.payload;
      if (action.payload === true) {
        //1 minutes
        state.websiteVersion.autoRefreshDateTime = Date.now() + (1 * 30 * 1000);
      }
    },
    setAutoRefreshTries: (state, action) => {
      state.websiteVersion.autoRefreshTries = action.payload;
      setCookieHours("autoRefreshTries", action.payload, 3);
    },
    setAutoRefreshDateTime: (state, action) => {
      state.websiteVersion.autoRefreshDateTime = action.payload;
    },
    setShowNavbar: (state, action) => {
      state.showNavbar = action.payload;
    },
    setShowFooter: (state, action) => {
      state.showFooter = action.payload;
    },
    setDtContent: (state, action) => {
      state.dtContent = action.payload;
    },
    setPageMeta: (state, action) => {
      state.pageMeta.title = action.payload.title;
      state.pageMeta.canonical = action.payload.canonical;
      state.pageMeta.description = action.payload.description;
      state.pageMeta.keywords = action.payload.keywords;
      state.pageMeta.seoID = action.payload.seoID;
      state.pageMeta.seoFor = action.payload.seoFor;
      state.pageMeta.seoProperty = action.payload.seoProperty
      state.pageMeta.customTitle = action.payload.customTitle;
      state.pageMeta.customDescription = action.payload.customDescription;
    },
    clearEmployeeUser: (state) => {
      localStorage.removeItem(UserCookie);
      state.EmployeeUser = EmployeeUserDefault;
    }
  },
});

export const {
  setEmployeeUser,
  setImageServer,
  setActiveDate,
  setTestDate,
  setTestDateActive,
  setWebsiteID,
  setWebsiteVersion,
  setWebsiteExpiration,
  setAutoRefresh,
  setAutoRefreshTries,
  setAutoRefreshDateTime,
  setShowNavbar,
  setShowFooter,
  setDtContent,
  setPageMeta,
  setCMSEditButton,
  clearEmployeeUser
} = siteWideSettingsSlice.actions;

export default siteWideSettingsSlice.reducer;
