import { createSlice } from "@reduxjs/toolkit";

// Retrieve the TcpMobileCUser cookie value
const cookie = document.cookie
  .split("; ")
  .find((cookie) => cookie.startsWith("TcpMobileCUser="));

// Extract the LoggedIn value from the cookie
const loggedInValue = cookie
  ? cookie
      .split("&")
      .find((part) => part.startsWith("LoggedIn="))
      .split("=")[1]
  : false;

// Extract the Username value from the cookie
const usernameValue = cookie
  ? cookie
      .split("&")
      .find((part) => part.startsWith("Name="))
      .split("=")[1]
  : "";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoggedIn: loggedInValue === "True",// used for production. i.e. for LIVE use
    // isLoggedIn: "true", // used for testing
    username: usernameValue,
  },
  reducers: {
    setLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
  },
});

export const { setLoggedIn } = authSlice.actions;

export default authSlice.reducer;
