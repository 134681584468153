import React, { useEffect, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loading from "../components/loading";
import ErrorBoundary from "../components/ErrorBoundary";
import ErrorMessage from "./errors/ErrorMessage";
import { setShowNavbar, setShowFooter, updatePageMeta } from "../Redux/siteWideSettingsSlice";
import AdminTool from "../components/AdminTool";

import "../styles/EmployeesBanner.scss";

const EmployeeSlider = lazy(() =>
  import("../components/Employee/EmployeeSlider.js")
);

const EmployeeSliderMobile = lazy(() =>
  import("../components/Employee/EmployeeSliderMobile.js")
);

function Employees() {
  const dispatch = useDispatch();
  dispatch(updatePageMeta("Employees Banner"));
  const isLoggedIn = useSelector((state) => state?.siteWideSettings?.EmployeeUser?.LoggedIn);
  const location = useLocation();

  const isMobile = window.innerWidth <= 767;

  // Set showNavbar to false when the component mounts
  useEffect(() => {
    dispatch(setShowNavbar(false));
    dispatch(setShowFooter(false));
  }, [dispatch]);

  // Reset showNavbar to true when the user leaves the page
  useEffect(() => {
    return () => {
      if (location.pathname !== "/employeesbanner") {
        dispatch(setShowNavbar(true));
        dispatch(setShowFooter(true));
      }
    };
  }, [dispatch, location.pathname]);

  return (
    <ErrorBoundary componentName="EmployeesBanner" fallback={<ErrorMessage />}>
      <>
        <Suspense fallback={<Loading />}>
          <div className="employee-slider-container">
            {isMobile ? <EmployeeSliderMobile /> : <EmployeeSlider />}
          </div>
          <div id="AdminToolStyling">
            {isLoggedIn && (
              <AdminTool />
            )}
          </div>

        </Suspense>
      </>
    </ErrorBoundary>
  );
}

export default Employees;


