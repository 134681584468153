import React, { useState, useEffect, Suspense } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Loading from "../../components/loading";
import "../../styles/_employeeinfologin.scss";
import ErrorBoundary from "../../components/ErrorBoundary";
import ErrorMessage from "../../pages/errors/ErrorMessage";
import { getEmployeeInfoLoginAsync } from "../../Redux/authTokenSlice";
import { setShowNavbar, setShowFooter, updatePageMeta } from "../../Redux/siteWideSettingsSlice";

function EmployeeInfoLogin() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  dispatch(updatePageMeta("Employee Info Login"));
  const authTokenEmployee = useSelector((state) => state.authToken.employee)
  const [userNumber, setUserNumber] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);

  const handleLogin = async () => {
    let ErrorMessage = '';

    if (userNumber === '') {
      ErrorMessage = 'Error: User Number is required';
    } else if (password === '') {
      ErrorMessage = 'Error: Password is required';
    } else {
      try {
        setLoginLoading(true);
        await dispatch(getEmployeeInfoLoginAsync(userNumber, password));
        setLoginLoading(false);
        if (authTokenEmployee.auth === true) {
          navigate('/employeeinformation');
        } else {
          ErrorMessage = 'Authentication failed. ' + authTokenEmployee.message;
        }
      } catch (error) {
        setLoginLoading(false);
        ErrorMessage = error.message || 'An error occurred during login.';
      }
    }

    setErrorMessage(ErrorMessage);
  };

  useEffect(() => {
    if (authTokenEmployee.auth === true) {
      navigate('/employeeinformation');
    }
  }, [authTokenEmployee.auth, navigate]);  

  useEffect(() => {
    const timer = setTimeout(() => {
      setErrorMessage("");
    }, 30000);
    return () => {
      clearTimeout(timer);
    };
  }, [errorMessage]);

  // Set showNavbar to false when the component mounts
  useEffect(() => {
    dispatch(setShowNavbar(false));
    dispatch(setShowFooter(false));
  }, [dispatch]);

  // Reset showNavbar to true when the user leaves the page
  useEffect(() => {
    return () => {
      if (location.pathname !== "/employeesbanner") {
        dispatch(setShowNavbar(true));
        dispatch(setShowFooter(true));
      }
    };
  }, [dispatch, location.pathname]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <ErrorBoundary componentName="EmployeesInfo" fallback={<ErrorMessage />}>
      <div className="employee-info-login-container">
        <h1>Employee Information Login</h1>
        <div className="employee-info-login-box">
          <div className="employee-info-login-input-group">
            <label htmlFor="userNumber">User Number</label>
            <input
              type="text"
              id="iuserNumber"
              value={userNumber}
              onChange={(e) => setUserNumber(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="employee-info-login-input-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="ipassword"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
          <button type="submit" className="employee-info-login-button" onClick={handleLogin}>
            {!loginLoading &&
              <>
                <span>Login</span>
              </>
            }
            {loginLoading &&
              <>
                <span style={{ paddingRight: '10px' }}>Login</span>
                <i className="fa fa-spinner fa-spin " />
              </>
            }
          </button>
          <div className='errorMessage'>
            {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default EmployeeInfoLogin;
